import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import CameraIcon from '@mui/icons-material/Camera';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


const createCustomTheme = (mode, backgroundColor) => createTheme({
  palette: {
    mode: mode,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          background-color: ${backgroundColor};
        }
      `,
    },
  },
});

const lightTheme = createCustomTheme('light', '#fff');
const darkTheme = createCustomTheme('dark', '#000');

async function fetchScreenshots(urls, widths, delay) {
  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/screenshot/responsiveshots.zip`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ urls, widths, delay }),
    });

    console.log('Received response from server:', response);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const blob = await response.blob();
    console.log('Received blob from server:', blob);

    if (blob.type === 'application/json') {
      const text = await blob.text();
      console.log('JSON error message from server:', text);
    } else {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'responsiveshots.zip';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
  }
}

function ResponsiveshotForm() {
  const [urlInput, setUrlInput] = useState('https://apple.com, https://www.samsung.com/, https://www.figma.com/');
  const [widthsInput, setWidthsInput] = useState('1920,1366,360');
  const [delayInput, setDelayInput] = useState('3');
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [theme, setTheme] = useState(prefersDarkMode ? darkTheme : lightTheme);
  const toggleTheme = () => {
    setTheme(theme === lightTheme ? darkTheme : lightTheme);
  };
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const urls = urlInput.split(',').map(url => url.trim());
    const widths = widthsInput.split(',').map(width => parseInt(width.trim()));
    const delay = parseInt(delayInput.trim());
    await fetchScreenshots(urls, widths, delay);
    setIsLoading(false);
  };

  useEffect(() => {
    document.title = 'Responsiveshot';
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        position="relative"
      >
        <Container maxWidth="sm">
          <Typography variant="h4" component="h1" gutterBottom>
            Take Screenshot
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="URLs (comma separated)"
              value={urlInput}
              onChange={(event) => setUrlInput(event.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Widths (comma separated)"
              value={widthsInput}
              onChange={(event) => setWidthsInput(event.target.value)}
              fullWidth
              margin="normal"
            />
            <Button onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}>
              Advanced Options {showAdvancedOptions ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
            {showAdvancedOptions && (
              <TextField
                label="Delay (in seconds)"
                value={delayInput}
                onChange={(event) => setDelayInput(event.target.value)}
                fullWidth
                margin="normal"
              />
            )}
            <LoadingButton 
              variant="contained" 
              size="large" 
              startIcon={<CameraIcon />} 
              color="primary" 
              type="submit"
              style={{ marginTop: '20px' }}
              fullWidth
              loading={isLoading}
            >
              Take Screenshot
            </LoadingButton>
          </form>
        </Container>
        <IconButton
          onClick={toggleTheme}
          style={{ position: 'absolute', top: 20, right: 20 }}
        >
          {theme === lightTheme ? <Brightness4Icon /> : <Brightness7Icon />}
        </IconButton>
      </Box>
    </ThemeProvider>
  );
}

export default ResponsiveshotForm;